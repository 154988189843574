import React, { Component } from "react";
import PropTypes from "prop-types";
import { SALES_TEAM } from "../../queries/queries";
import { graphql } from "react-apollo";
import { Select, Divider } from "antd";

const { Option } = Select;

class OwnerDropdownInputGroup extends Component {
  displayOwner() {
    let data = this.props.data;
    if (data.loading) {
      return <Option value="">Select:</Option>;
    } else {
      let owners = data.User;
      return owners.map(user => {
        return (
          <Option key={user.id} value={user.id}>
            {user.name}
          </Option>
        );
      });
    }
  }

  render() {
    return (
      <div className="form-group">
        <label htmlFor={this.props.name}>{this.props.label}</label>

        <Select
          style={{
            width: "200"
          }}
          dropdownRender={menu => (
            <div>
              {menu}
              <Divider style={{ margin: "4px 0" }} />
              <div style={{ padding: "8px", cursor: "pointer" }} />
            </div>
          )}
          placeholder={this.props.placeholder}
          type={this.props.type}
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.onChange}
          onSearch={this.props.onSearch}
        >
          {this.displayOwner()}
        </Select>
      </div>
    );
  }
}

export default graphql(SALES_TEAM)(OwnerDropdownInputGroup);

OwnerDropdownInputGroup.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string
};

OwnerDropdownInputGroup.defaultProps = {
  type: "select"
};
