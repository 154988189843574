import React, { Component } from "react";
import PropTypes from "prop-types";
import { ALL_COMPANIES } from "../../queries/queries";
import { graphql } from "react-apollo";
import VirtualizedSelect from "react-virtualized-select";
import "react-select/dist/react-select.css";
import "react-virtualized/styles.css";
import "react-virtualized-select/styles.css";
import _ from "lodash";

class PartnerInputGroup extends Component {
  displayPartner() {
    let data = this.props.data;
    if (data.loading) {
      return null;
    } else {
      const companies = data.Company.map(partners => ({
        value: `${partners.id}`,
        label: `${partners.name}`
      }));
      const sortedCompanies = _.orderBy(
        companies,
        [partner => partner.label.toLowerCase()],
        ["asc"]
      );
      return sortedCompanies;
    }
  }
  render() {
    return (
      <div className="form-group">
        <VirtualizedSelect
          style={{ lineHeight: "10px" }}
          options={this.displayPartner()}
          simpleValue
          clearable
          type={this.props.type}
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.onChange}
          searchable
          labelKey="label"
          valueKey="value"
          placeholder={this.props.placeholder}
        />
      </div>
    );
  }
}

export default graphql(ALL_COMPANIES)(PartnerInputGroup);

PartnerInputGroup.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string
};

PartnerInputGroup.defaultProps = {
  type: "select"
};
