import React, { Component } from "react";
import PropTypes from "prop-types";
import { POC_CONTACTS } from "../../queries/queries";
import { graphql } from "react-apollo";
import { Select, Divider } from "antd";

const { Option } = Select;

class POCInputGroup extends Component {
  displayContacts() {
    let data = this.props.data;
    if (data.loading) {
      return <Option value="">Select:</Option>;
    } else {
      return data.Contact.map(contacts => {
        return (
          <Option key={contacts.id} value={contacts.id}>
            {contacts.name}
          </Option>
        );
      });
    }
  }

  render() {
    return (
      <div className="form-group">
        <Select
          value={this.props.value}
          style={{ width: "200" }}
          dropdownRender={menu => (
            <div>
              {menu}
              <Divider style={{ margin: "4px 0" }} />
              <div style={{ padding: "8px", cursor: "pointer" }} />
            </div>
          )}
          placeholder={this.props.placeholder}
          type={this.props.type}
          name={this.props.name}
          poc={this.props.client}
          onChange={this.props.onChange}
          onSearch={this.props.onSearch}
        >
          {this.displayContacts()}
        </Select>
      </div>
    );
  }
}

export default graphql(POC_CONTACTS, {
  options: props => ({ variables: { company: props.poc } })
})(POCInputGroup);

POCInputGroup.propTypes = {
  value: PropTypes.string,
  poc: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string
};

POCInputGroup.defaultProps = {
  type: "select"
};
