import React, { Component } from "react";
import StageDropdownInputGroup from "../layout/StageDropdownInputGroup";
import OwnerDropdownInputGroup from "../layout/OwnerDropdownInputGroup";
import PartnerInputGroup from "../layout/PartnerInputGroup";
import ClientMultipleInputGroup from "../layout/ClientMultipleInputGroup";
import "antd/dist/antd.css";
import { Button, Form } from "antd";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 28 },
    sm: { span: 12 }
  }
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 18
    }
  }
};

class AddPartnership extends Component {
  // Continue to next Page/Step
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        return values;
      }
    });
  };

  render() {
    const { values, handleChange } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="card mb-3">
        <div className="card-header" style={{ backgroundColor: "#f1f1f1" }}>
          <h1 style={{ fontSize: "18px", fontWeight: "1em" }}>
            Create Multiple Partnerships
          </h1>
        </div>
        <div className="card-body">
          <Form
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto"
            }}
            {...formItemLayout}
          >
            <Form.Item label="WB6 Owner">
              {getFieldDecorator("owner", {
                rules: [{ required: true, message: "Please select an Owner!" }]
              })(
                <OwnerDropdownInputGroup
                  name="owner"
                  type="owner"
                  placeholder="Select..."
                  onChange={handleChange("owner")}
                  error={values.errors.owner}
                />
              )}
            </Form.Item>
            <Form.Item label="Partner">
              {getFieldDecorator("partner", {
                rules: [{ required: true, message: "Please select a Partner!" }]
              })(
                <PartnerInputGroup
                  name="partner"
                  type="partner"
                  placeholder="Start Typing..."
                  onChange={handleChange("partner")}
                  error={values.errors.partner}
                />
              )}
            </Form.Item>
            <Form.Item label="Client(s)">
              {getFieldDecorator("selectedClients", {
                rules: [{ required: true, message: "Please select Clients!" }]
              })(
                <ClientMultipleInputGroup
                  mode="multiple"
                  name="Clients"
                  type="Clients"
                  placeholder="Select Client(s):"
                  onChange={handleChange("selectedClients")}
                  error={values.errors.selectedClients}
                />
              )}
            </Form.Item>
            <Form.Item label="Partnership Stage">
              {getFieldDecorator("stage", {
                rules: [
                  {
                    required: true,
                    message: "Please select a Partnership Stage!"
                  }
                ]
              })(
                <StageDropdownInputGroup
                  name="stage"
                  type="stage"
                  placeholder="Select..."
                  onChange={handleChange("stage")}
                  error={values.errors.stage}
                />
              )}
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              <Button
                style={{ backgroundColor: "#6f0a56", borderColor: "#6f0a56" }}
                type="primary"
                onClick={this.continue}
              >
                Next
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default AddPartnership;
