import React, { Component } from "react";
import PropTypes from "prop-types";
import { ACTIVE_CLIENT_QUERY } from "../../queries/queries";
import { graphql } from "react-apollo";
import _ from "lodash";
import { Select, Divider } from "antd";

const { Option } = Select;

class ClientMultipleInputGroup extends Component {
  displayClients() {
    let data = this.props.data;
    if (data.loading) {
      return null;
    } else {
      const clients = data.CompaniesbyStatus.map(clients => ({
        value: `${clients.id}`,
        label: `${clients.name}`
      }));
      const sortedClients = _.orderBy(
        clients,
        [client => client.label.toLowerCase()],
        ["asc"]
      );
      return sortedClients.map(sortedClients => (
        <Option key={sortedClients.value}>{sortedClients.label}</Option>
      ));
    }
  }

  render() {
    return (
      <div className="form-group">
        <Select
          style={{
            width: "200"
          }}
          dropdownRender={menu => (
            <div>
              {menu}
              <Divider style={{ margin: "4px 0" }} />
              <div style={{ padding: "8px", cursor: "pointer" }} />
            </div>
          )}
          labelInValue
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          mode={this.props.mode}
          type={this.props.type}
          name={this.props.name}
          placeholder={this.props.placeholder}
          onChange={this.props.onChange}
        >
          {this.displayClients()}
        </Select>
      </div>
    );
  }
}

ClientMultipleInputGroup.propTypes = {
  mode: PropTypes.string.isRequired,

  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,

  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string
};

ClientMultipleInputGroup.defaultProps = {
  type: "select"
};

export default graphql(ACTIVE_CLIENT_QUERY)(ClientMultipleInputGroup);
