import React, { Component } from "react";
import TextInputGroup from "../layout/TextInputGroup";
import POCInputGroup from "../layout/POCInputGroup";
import CalendarInputGroup from "../layout/CalendarInputGroup";
import axios from "axios";
import { Button, Form } from "antd";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 28 },
    sm: { span: 12 }
  }
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};

class ClientInfo extends Component {
  createPartnership = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // PROCESS FORM
        const {
          owner,
          partner,
          clientId,
          stage,
          poc1,
          poc2,
          initialContact,
          timeline,
          description
        } = this.props.values;

        const data = {
          owner: owner,
          company1: clientId,
          company2: partner,
          poc_company1: poc1,
          poc_company2: poc2,
          status: stage,
          initialContact: initialContact,
          timeline: timeline,
          description: description
        };
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json",
            "X-Knack-Application-Id": "592dd6f073bc704b6a235563",
            "X-Knack-REST-API-KEY": "cc224a6a-e228-4182-9ee0-218696dc4104"
          }
        };
        //webhook integromat : https://hook.integromat.com/c2n4q7m4jqvm67q6dnmxkmrcp65hkqox
        //knack url: https://api.knack.com/v1/objects/object_8/records/
        axios
          .post(
            "https://hook.integromat.com/c2n4q7m4jqvm67q6dnmxkmrcp65hkqox",
            data,
            axiosConfig
          )
          .then(res => {
            console.log("RESPONSE RECEIVED: ", res);
          })
          .catch(err => {
            console.log("AXIOS ERROR: ", err);
          });

        console.log(data);
        this.props.nextStep();
        this.props.clearClientInfo();
        this.props.form.resetFields();

        return values;
      }
    });
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, handleChange } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="card mb-3">
        <div className="card-header" style={{ backgroundColor: "#f1f1f1" }}>
          <h1 style={{ fontSize: "18px", fontWeight: "1em" }}>
            Create Multiple Partnerships
          </h1>
        </div>
        <div className="card-body">
          <Form
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto"
            }}
            {...formItemLayout}
          >
            <h3
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto"
              }}
            >
              {values.client}
            </h3>

            <Form.Item label="Client POC">
              {getFieldDecorator("poc1", {
                rules: [
                  {
                    required: true,
                    message: "Please select the client's POC!"
                  }
                ]
              })(
                <POCInputGroup
                  name="poc1"
                  type="poc1"
                  placeholder="Select..."
                  poc={values.clientId}
                  onChange={handleChange("poc1")}
                  error={values.errors.poc1}
                />
              )}
            </Form.Item>
            <Form.Item label="Partner POC">
              {getFieldDecorator("poc2", {
                rules: [
                  {
                    required: true,
                    message: "Please select the partner's POC!"
                  }
                ]
              })(
                <POCInputGroup
                  name="poc2"
                  type="poc2"
                  placeholder="Select..."
                  poc={values.partner}
                  onChange={handleChange("poc2")}
                  error={values.errors.poc2}
                />
              )}
            </Form.Item>
            <Form.Item label="Initial Contact Date">
              {getFieldDecorator("initialContact", {
                rules: [
                  {
                    required: true,
                    message: "Please select an initial contact date!"
                  }
                ]
              })(
                <CalendarInputGroup
                  name="initialContact"
                  type="initialContact"
                  placeholder="Select Date...."
                  onChange={handleChange("initialContact")}
                  error={values.errors.initialContact}
                />
              )}
            </Form.Item>
            <Form.Item label="Partnership Timeline">
              {getFieldDecorator("timeline", {
                rules: [
                  {
                    required: true,
                    message: "Please enter a Partnership Timeline!"
                  }
                ]
              })(
                <TextInputGroup
                  name="timeline"
                  type="timeline"
                  onChange={handleChange("timeline")}
                  error={values.errors.timeline}
                />
              )}
            </Form.Item>
            <Form.Item label="Partnership Description">
              {getFieldDecorator("description", {
                rules: [
                  {
                    required: true,
                    message: "Please enter a Partnership Description!"
                  }
                ]
              })(
                <TextInputGroup
                  name="description"
                  type="description"
                  onChange={handleChange("description")}
                  error={values.errors.description}
                />
              )}
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              <Button
                style={{
                  backgroundColor: "#6f0a56",
                  borderColor: "#6f0a56"
                }}
                type="primary"
                onClick={this.createPartnership}
              >
                Create Partnership & Next
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default ClientInfo;
