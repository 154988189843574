import React, { Component } from "react";
import PropTypes from "prop-types";
import { PARTNERSHIP_STAGE } from "../../queries/queries";
import { graphql } from "react-apollo";
import { Select, Divider } from "antd";
import _ from "lodash";

const { Option } = Select;

class StageDropdownInputGroup extends Component {
  // displayStage() {
  //   let data = this.props.data;
  //   if (data.loading) {
  //     return <Option value="">Select:</Option>;
  //   } else {
  //     let removeDuplicates = _.uniqBy(data.Partnership, "stage");
  //     const sortedStages = _.orderBy(
  //       removeDuplicates,
  //       [stages => stages.stage.toLowerCase()],
  //       ["asc"]
  //     );
  //     return sortedStages.map(stages => {
  //       return (
  //         <Option key={stages.stage} value={stages.stage}>
  //           {stages.stage}
  //         </Option>
  //       );
  //     });
  //   }
  // }

  render() {
    return (
      <div className="form-group">
        <Select
          style={{ width: "200" }}
          dropdownRender={menu => (
            <div>
              {menu}
              <Divider style={{ margin: "4px 0" }} />
              <div style={{ padding: "8px", cursor: "pointer" }} />
            </div>
          )}
          placeholder={this.props.placeholder}
          type={this.props.type}
          name={this.props.name}
          onChange={this.props.onChange}
          onSearch={this.props.onSearch}
        >
          <Option value="Outreach Pending">Outreach Pending</Option>
          <Option value="Outreach Sent">Outreach Sent</Option>
          <Option value="Scheduling Meeting">Scheduling Meeting</Option>
          <Option value="Holding Pen">Holding Pen</Option>
          <Option value="Ongoing Negotiations">Ongoing Negotiations</Option>
          <Option value="Introduction Complete">Introduction Complete</Option>
          <Option value="Contract Sent">Contract Sent</Option>
          <Option value="Awaiting Countersignature">
            Awaiting Countersignature
          </Option>
          <Option value="Contract Signed">Contract Signed</Option>
          <Option value="Campaign Implementation">
            Campaign Implementation
          </Option>
          <Option value="Campaign Launched">Campaign Launched</Option>
          <Option value="Launched/Paused">Launched/Paused</Option>
          <Option value="Not Interested">Not Interested</Option>
          <Option value="Deal Failed">Deal Failed</Option>
        </Select>
      </div>
    );
  }
}

export default graphql(PARTNERSHIP_STAGE)(StageDropdownInputGroup);

StageDropdownInputGroup.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string
};

StageDropdownInputGroup.defaultProps = {
  type: "select"
};
