import React from "react";

import "./App.css";

import PartnershipForm from "./components/form/PartnershipForm";

import "bootstrap/dist/css/bootstrap.min.css";

class App extends React.Component {
  render() {
    return (
      <div>
        <PartnershipForm />
      </div>
    );
  }
}

export default App;
