import React, { Component } from "react";
import AddPartnership from "./AddPartnership";
import ClientInfo from "./ClientInfo";
import Success from "./Success";
import NotFound from "../pages/NotFound";
import moment from "moment";
import { Form } from "antd";
import _ from "lodash";

class PartnershipForm extends Component {
  state = {
    step: 1,
    owner: "",
    createdDate: "",
    stage: "",
    partner: "",
    client: "",
    clientId: "",
    selectedClients: [],
    poc1: "",
    poc2: "",
    initialContact: moment(),
    timeline: "",
    description: "",
    errors: {}
  };

  // Proceed to next step
  nextStep = () => {
    const { step, selectedClients } = this.state;

    if (step === 1 || selectedClients.length === 0) {
      this.setState({
        step: step + 1
      });
    }

    if (selectedClients.length !== 0) {
      this.setState({
        client: selectedClients[0].label,
        clientId: selectedClients[0].key
      });
    }

    //Removes first company in selected clients array
    selectedClients.shift();
  };

  // Prev Step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };

  // Handle fields change
  handleChange = input => e => {
    input === "description" || input === "timeline"
      ? this.setState({ [input]: e.target.value })
      : this.setState({ [input]: e });
  };

  //Clear state
  clearClientInfo = () => {
    this.setState({
      poc1: "",
      poc2: "",
      initialContact: "",
      timeline: "",
      description: ""
    });
  };

  render() {
    const { step } = this.state;
    const {
      owner,
      createdDate,
      addToPSM,
      stage,
      partner,
      client,
      clientId,
      selectedClients,
      poc1,
      poc2,
      initialContact,
      timeline,
      description,
      errors
    } = this.state;
    const values = {
      owner,
      createdDate,
      addToPSM,
      stage,
      partner,
      client,
      clientId,
      selectedClients,
      poc1,
      poc2,
      initialContact,
      timeline,
      description,
      errors
    };

    switch (step) {
      case 1:
        return (
          <AddPartnership
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
            form={this.props.form}
          />
        );
      case 2:
        return (
          <ClientInfo
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            clearClientInfo={this.clearClientInfo}
            handleChange={this.handleChange}
            values={values}
            form={this.props.form}
          />
        );
      case 3:
        return <Success />;
      default:
        return <NotFound />;
    }
  }
}

export default Form.create()(PartnershipForm);
