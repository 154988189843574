import React from "react";
import { Button } from "antd";

class Success extends React.Component {
  render() {
    return (
      <div className="card mb-3">
        <div
          className="card-body"
          style={{
            display: "block",
            marginTop: "100px",
            marginBottom: "auto",
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          <h1
            style={{
              textAlign: "center",
              fontSize: "24px",
              fontWeight: "1em"
            }}
          >
            Thank you!
          </h1>
          <br />
          <h3
            style={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "1em"
            }}
          >
            Your submissions have been received.
          </h3>
          <br />
          <Button
            style={{
              color: "white",
              backgroundColor: "#6f0a56",
              borderColor: "#6f0a56"
            }}
            href="https://workbook6.knack.com/crm#activeclientpipeline/"
            type="link"
          >
            Go to Partnership Pipeline
          </Button>
        </div>
      </div>
    );
  }
}

export default Success;
