import { gql } from "apollo-boost";

const ACTIVE_CLIENT_QUERY = gql`
  {
    CompaniesbyStatus(status: "Active") {
      id
      name
    }
  }
`;

const ALL_COMPANIES = gql`
  {
    Company {
      id
      name
    }
  }
`;

const SALES_TEAM = gql`
  {
    User(status: "active") {
      id
      name
    }
  }
`;

const PARTNERSHIP_TYPE = gql`
  {
    Partnership {
      type
    }
  }
`;

const PARTNERSHIP_STAGE = gql`
  {
    Partnership {
      stage
    }
  }
`;

const POC_CONTACTS = gql`
  query($company: String!) {
    Contact(companyIdString: $company) {
      id
      name
    }
  }
`;

export {
  ACTIVE_CLIENT_QUERY,
  ALL_COMPANIES,
  SALES_TEAM,
  PARTNERSHIP_TYPE,
  PARTNERSHIP_STAGE,
  POC_CONTACTS
};
