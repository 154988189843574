import React, { Component } from "react";
import PropTypes from "prop-types";
import { DatePicker } from "antd";

const dateFormat = "MM/DD/YYYY";

class CalendarInputGroup extends Component {
  render() {
    return (
      <div className="form-group">
        <DatePicker
          value={this.props.value}
          format={dateFormat}
          placeholder={this.props.placeholder}
          name={this.props.name}
          onChange={this.props.onChange}
        />
      </div>
    );
  }
}
export default CalendarInputGroup;

CalendarInputGroup.propTypes = {
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
