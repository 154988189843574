import React, { Component } from "react";
import PropTypes from "prop-types";
import { Input } from "antd";

const { TextArea } = Input;

class TextInputGroup extends Component {
  render() {
    return (
      <div className="form-group">
        <TextArea
          value={this.props.value}
          type={this.props.type}
          name={this.props.name}
          onChange={this.props.onChange}
        />
      </div>
    );
  }
}

TextInputGroup.propTypes = {
  name: PropTypes.string.isRequired,

  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string
};

TextInputGroup.defaultProps = {
  type: "text"
};

export default TextInputGroup;
